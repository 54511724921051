import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import RewardCard from "~common/components/cards/RewardCard";
import LoadingGuard from "~common/components/loading/LoadingGuard";
import { useTracking, useTrackPageView } from "~common/tracking";
import LandingPagePanel from "~src/components/landing/LandingPagePanel";
import LandingPageRewardCard from "~src/components/landing/LandingPageRewardCard";
import YouCanStillEarnCredit from "~src/components/landing/YouCanStillEarnCredit";
import useGift from "~src/hooks/services/useGift";
import useAlertModal from "~src/hooks/useAlertModal";
import { selectAuthUserInfo } from "~src/store";
import { setShowAppAdoptionNux } from "~src/store/slices/nuxs-slice";
import {
  setClaimedGift,
  setClaimedGiftSecret,
  setClaimedReferralCode,
} from "~src/store/slices/user-slice";
import { giftInfo } from "~src/utils/gifts";

const LandingGift: React.VFC = () => {
  const { trackEvent } = useTracking();
  const { giftSecret } = useParams();
  const { gift, loading, error } = useGift(giftSecret || "");
  const dispatch = useDispatch();
  const authUserInfo = useSelector(selectAuthUserInfo);
  const setAlertModal = useAlertModal();

  useTrackPageView("Claim Gift Landing Page");

  const { isOwnGift, isClaimed, isExpired, isClaimable } = giftInfo(
    gift,
    authUserInfo?.userId,
    !!error
  );

  useEffect(() => {
    dispatch(setShowAppAdoptionNux(false));
  }, [dispatch]);

  useEffect(() => {
    if (isClaimed) {
      trackEvent("Gift already claimed landing page");
    } else if (isExpired) {
      trackEvent("Gift expired landing page");
    }
  }, [trackEvent, isExpired, isClaimed]);

  useEffect(() => {
    // Save gift info and referral code to store for claiming
    // after authentication.
    if (gift) {
      dispatch(setClaimedReferralCode(gift.sender_referral_code));
      if (isClaimable) {
        dispatch(setClaimedGiftSecret(giftSecret));
        dispatch(setClaimedGift(gift));
      }
    }
  }, [isClaimable, dispatch, gift, giftSecret]);

  if (isOwnGift) {
    setAlertModal.cantClaimOwnGift();

    return <Navigate to="/home" replace />;
  }

  let contents;
  let ctaCopy = "Continue with Catch";
  let containerProps = {
    bgcolor: "tertiary.main",
    color: "common.white",
    pb: 2,
  };

  if (isClaimed) {
    contents = (
      <>
        <Typography variant="h1" sx={{ mb: 4 }}>
          This gift has already been claimed, but hey...
        </Typography>
        <YouCanStillEarnCredit />
      </>
    );
  } else if (isExpired) {
    contents = (
      <>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Sorry, this gift link has expired.
        </Typography>
        <Typography variant="bodyLarge">
          Please contact the sender for a new gift link.
        </Typography>
      </>
    );
  } else if (!gift || error) {
    contents = (
      <>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Looks like that code isn&apos;t valid, but hey...
        </Typography>
        <YouCanStillEarnCredit />
      </>
    );
  } else {
    ctaCopy = "Claim your gift";
    contents = (
      <>
        <LandingPageRewardCard>
          <RewardCard reward={gift} sx={{ width: 224, mb: 6 }} />
        </LandingPageRewardCard>

        <Typography variant="h1">Pay with Catch. Earn $$.</Typography>

        <Typography variant="h1" color="primary.main">
          Your debit card glow-up.
        </Typography>
      </>
    );
    containerProps = {
      bgcolor: "common.white",
      color: "grey.700",
      pb: 0,
    };
  }

  return (
    <Box {...containerProps}>
      <LandingPagePanel ctaCopy={ctaCopy}>
        <LoadingGuard {...{ loading }}>{contents}</LoadingGuard>
      </LandingPagePanel>
    </Box>
  );
};

export default LandingGift;
