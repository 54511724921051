import React, { useCallback, useState } from "react";

import { Box, Stack } from "@mui/material";

import DefaultDialog from "~common/components/modals/DefaultDialog";
import { useUniqueId } from "~common/hooks/accessibility-hooks";
import { Reward, RewardPool, useCreateRewardPoolEntry } from "~common/services";
import useUserRewards from "~src/hooks/services/useUserRewards";
import useRefreshCreditExchange from "~src/hooks/useRefreshCreditExchange";

import MyCreditsCard from "../MyCreditsCard";
import ChooseCreditToGiftModalContent from "./ChooseCreditToGiftModalContent";
import ChooseRewardPoolsModalContent from "./ChooseRewardPoolsModalContent";

type GiftToCreditExchangeModalProps = {
  open: boolean;
  onClose: () => void;
};

const GiftToCreditExchangeModal: React.VFC<GiftToCreditExchangeModalProps> = ({
  open,
  onClose,
}) => {
  const { userRewards } = useUserRewards();
  const { mutate: createRewardPoolEntry, loading: loadingEntryCreation } =
    useCreateRewardPoolEntry();
  const [selectedRewards, setSelectedRewards] = useState<Reward[]>([]);
  const refreshCreditExchange = useRefreshCreditExchange();

  const dialogId = useUniqueId("create-reward-pool-entry-modal");
  const dialogDescriptionId = useUniqueId(
    "create-reward-pool-entry-modal-description"
  );

  const wrappedOnClose = useCallback(() => {
    onClose();
    setSelectedRewards([]);
  }, [onClose, setSelectedRewards]);

  const handleSubmit = useCallback(
    async (pools: RewardPool[]) => {
      const poolIds = pools.map((pool) => pool.pool_id);
      const giftRequests = selectedRewards.map((reward) =>
        createRewardPoolEntry({
          reward_pool_ids: poolIds,
          reward_ids: reward.reward_ids,
          merchant_id: reward.merchant_id,
          merchant_name: reward.merchant_name || "",
          amount: reward.amount,
        })
      );
      await Promise.all(giftRequests);
      refreshCreditExchange();
      wrappedOnClose();
    },
    [
      createRewardPoolEntry,
      selectedRewards,
      refreshCreditExchange,
      wrappedOnClose,
    ]
  );

  return (
    <DefaultDialog
      fullBleed
      open={open}
      onClose={wrappedOnClose}
      ariaLabelledBy={dialogId}
      ariaDescribedBy={dialogDescriptionId}
      sx={({ breakpoints, shape }) => ({
        "& .MuiPaper-root.MuiDialog-paper": {
          pt: 26,
          borderRadius: `${shape.borderRadius * 2}px`,
          [breakpoints.down("sm")]: {
            left: "auto",
          },
        },
      })}
    >
      <Stack
        alignItems="center"
        sx={{
          textAlign: "center",
          px: 6,
          py: 3,
          marginBottom: 6,
        }}
      >
        <Stack alignItems="center" spacing={6} sx={{ px: 6, pb: 8 }}>
          <Box sx={{ position: "relative", height: 140, width: "100%" }}>
            {userRewards.map((reward, idx) => (
              <Box
                sx={{
                  width: 224,
                  left: idx * 44,
                  top: 0,
                  position: "absolute",
                }}
                key={reward.reward_ids.join("+")}
              >
                <MyCreditsCard reward={reward} readOnly />
              </Box>
            ))}
          </Box>
          {selectedRewards.length <= 0 ? (
            <ChooseCreditToGiftModalContent
              onContinue={setSelectedRewards}
              dialogId={dialogId}
              dialogDescriptionId={dialogDescriptionId}
            />
          ) : (
            <ChooseRewardPoolsModalContent
              onSubmit={handleSubmit}
              loadingEntryCreation={loadingEntryCreation}
              dialogId={dialogId}
              dialogDescriptionId={dialogDescriptionId}
            />
          )}
        </Stack>
      </Stack>
    </DefaultDialog>
  );
};

export default GiftToCreditExchangeModal;
