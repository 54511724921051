import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AlertModalData = null | {
  title: string;
  subtitle: string;
  logoutOnClose?: boolean;
};

type CoreState = {
  // Whether the application has finished initial loading.
  isLoading: boolean;
  // Global state for the <AlertModal />.
  alertModal: AlertModalData;
  // Mobile menu visibility.
  menuOpen: boolean;
  // Prefill data from the URL querystring parameters.
  prefill: Prefill;
  // Whether the user is arriving to the user portal from a reward boost email.
  fromRewardBoostEmail: boolean;
  // Is user portal embedded within a webview in a mobile app
  isMobileApp: boolean;
};

const initialState: CoreState = {
  isLoading: true,
  alertModal: null,
  menuOpen: false,
  prefill: {
    prefillPhone: null,
    prefillFirstName: null,
    prefillLastName: null,
    prefillEmail: null,
  },
  fromRewardBoostEmail: false,
  isMobileApp: false,
};

type Prefill = {
  prefillPhone: string | null;
  prefillFirstName: string | null;
  prefillLastName: string | null;
  prefillEmail: string | null;
};

const slice = createSlice({
  name: "core",
  initialState,
  reducers: {
    stopLoading: (state) => {
      state.isLoading = false;
    },
    closeAlertModal: (state) => {
      state.alertModal = null;
    },
    openAlertModal: (state, action: PayloadAction<AlertModalData>) => {
      state.alertModal = action.payload;
    },
    setMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.menuOpen = action.payload;
    },
    setPrefill: (state, action: PayloadAction<Prefill>) => {
      state.prefill = action.payload;
    },
    markFromRewardBoostEmail: (state) => {
      state.fromRewardBoostEmail = true;
    },
    setIsMobileApp: (state, action: PayloadAction<boolean>) => {
      state.isMobileApp = action.payload;
    },
  },
});

export const {
  stopLoading,
  closeAlertModal,
  openAlertModal,
  setMenuOpen,
  markFromRewardBoostEmail,
  setPrefill,
  setIsMobileApp,
} = slice.actions;
export default slice.reducer;
export type { AlertModalData };
