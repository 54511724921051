import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lock as LockIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import useHandleSaveCard from "../../hooks/bank-linking/useHandleSaveCard";
import useHideIntercom from "../../hooks/useHideIntercom";
import useMatchesMedia from "../../hooks/useMatchesMedia";
import {
  selectHideAdyenModal,
  selectIsCatchPassFlow,
  selectIsProcessingLinkUpdate,
  selectIsZipCodeValid,
  selectShowZipCodeError,
} from "../../store/selectors";
import { setHideAdyenModal } from "../../store/slices/bankLinking-slice";
import { BankButton } from "../controls/buttons";
import HoldAuthorization from "./HoldAuthorization";

type AdyenCardInputProps = {
  headingId: string;
  ready: boolean;
  inputsValid: boolean;
  inline?: boolean;
  displayButton?: boolean;
  mode?: "dark" | "light";
};

const AdyenCardInput: React.VFC<AdyenCardInputProps> = ({
  headingId,
  ready,
  inputsValid,
  inline,
  displayButton,
  mode,
}) => {
  const isMobile = useMatchesMedia();
  const dispatch = useDispatch();
  const { handleSaveCardClick } = useHandleSaveCard({
    disableLoadingScreen: false,
  });
  const hideAdyenModal = useSelector(selectHideAdyenModal);
  const zipErrorMessage = useSelector(selectShowZipCodeError);
  const zipValid = useSelector(selectIsZipCodeValid);
  const isCatchPassFlow = useSelector(selectIsCatchPassFlow);
  const isProcessingLinkUpdate = useSelector(selectIsProcessingLinkUpdate);
  const saveCardButtonText = isCatchPassFlow ? "Continue" : "Save card";

  useHideIntercom((!hideAdyenModal && isMobile) || isCatchPassFlow);

  const handleSaveCard = () => {
    if (inputsValid && zipValid) {
      dispatch(setHideAdyenModal(true));
      void handleSaveCardClick();
    }
  };

  return (
    <Stack
      alignItems="center"
      // This and the minHeight on the loading element are to force the
      // overall modal not to resize as the Adyen component loads and then mounts
      sx={({ breakpoints, palette }) => ({
        [breakpoints.up("sm")]: {
          // (NB: 400px - 48px internal padding)
          width: inline ? "100%" : 352,
        },
        width: `calc(100vw - 48px)`,
        maxWidth: "100%",
        ".adyen-checkout__spinner": {
          border: `2px solid ${
            mode === "dark" ? palette.common.white : palette.grey[700]
          }`,
          borderTopColor: "transparent",
          width: "24px",
          height: "24px",
        },
      })}
    >
      <Stack alignItems="center">
        {/* NB: Ensure even without the title we have an element with the
        right ID to mount the Adyen component on */}
        {inline ? (
          <div id={headingId} />
        ) : (
          <Typography
            id={headingId}
            variant="h3"
            component="span"
            sx={{ mb: 4 }}
          >
            Link your debit card instantly
          </Typography>
        )}

        {!ready ? (
          <Box
            sx={{
              minHeight: 184,
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* To match the spinner that is in the Adyen web component by default, instead
            of using our normal CircularProgress component, we use a customized version of
            the Adyen spinner */}
            <div className="adyen-checkout__spinner adyen-checkout__spinner--large" />
          </Box>
        ) : (
          <Box
            id="adyen-debit-mount"
            onKeyPress={(e) => {
              if (e.key === "Enter" && inputsValid && !inline) {
                handleSaveCard();
              }
            }}
            sx={({ palette, typography }) => ({
              minHeight: 184,
              ".adyen-checkout__field--cardNumber": {
                mb: 2,
              },
              ".adyen-checkout__fieldset--billingAddress": {
                mt: 2,
              },
              ".adyen-checkout__field-wrapper>.adyen-checkout__field:first-child":
                {
                  mr: 1.5,
                },
              ".adyen-checkout__field-wrapper>.adyen-checkout__field:nth-child(2)":
                {
                  ml: 1.5,
                },
              ".adyen-checkout__input-wrapper": {
                input: {
                  pl: 4,
                },
              },
              ".adyen-checkout__error-text": {
                ml: 4,
              },
              ".adyen-checkout__card__cvc__hint__wrapper, .CardInput-module_card-input__icon__2Iaf5, .adyen-checkout-input__inline-validation, .adyen-checkout__fieldset__title, .adyen-checkout__field__exp-date_hint_wrapper, .adyen-checkout-form-instruction, .adyen-checkout__label__text, .adyen-checkout__helper-text":
                {
                  display: "none",
                },
              ...(mode === "dark"
                ? {
                    ".adyen-checkout__input": {
                      ...typography.bodyLarge,
                      iframe: {
                        ml: 2,
                      },
                      height: 52,
                      background: "transparent",
                      borderColor: palette.tints?.purple?.main,
                      color: palette.common.white,
                      ":active, :focus": {
                        border: `2px solid ${
                          palette.tints?.purple?.main || ""
                        }`,
                        boxShadow: "none",
                      },
                    },
                    ".adyen-checkout__input--error": {
                      border: `1px solid ${palette.tints?.pink?.dark || ""}`,
                    },
                    ".adyen-checkout__input::placeholder": {
                      color: palette.common.white,
                    },
                    ".adyen-checkout__input--focus": {
                      border: `2px solid ${palette.tints?.purple?.main || ""}`,
                      boxShadow: "none",
                      ":hover": {
                        border: `2px solid ${
                          palette.tints?.purple?.main || ""
                        }`,
                      },
                    },
                    ".adyen-checkout__label--focused .adyen-checkout__label__text":
                      {
                        color: palette.common.white,
                        "&.adyen-checkout__label__text--error": {
                          color: palette.error.light,
                        },
                        "&.zipcode-error": {
                          color: palette.error.light,
                        },
                      },
                    ".zipcode-error": {
                      color: palette.error.light,
                    },
                    "input.zipcode-error": {
                      border: `1px solid ${palette.tints?.pink?.dark || ""}`,
                      color: palette.error.light,
                    },
                    ".adyen-checkout__label__text--error, .adyen-checkout__error-text":
                      {
                        color: palette.tints?.pink?.dark,
                      },
                  }
                : {
                    ".adyen-checkout__input": {
                      ...typography.bodyLarge,
                      iframe: {
                        ml: 2,
                      },
                      height: "48px",
                      borderColor: palette.grey[500],
                      color: palette.grey[700],
                      ":active, :focus": {
                        border: `2px solid ${palette.grey[700]}`,
                        boxShadow: "none",
                      },
                    },
                    ".adyen-checkout__input--error": {
                      border: `1px solid ${palette.error.main}`,
                    },
                    ".adyen-checkout__input::placeholder": {
                      // NB: this is necessary as the styling passed into the AdyenCheckout
                      // only applies to the secure fields
                      color: palette.grey[500],
                      // We can't update the font weight of the placeholders, so we increase the
                      // font weight of "Zip Code" to match
                      fontWeight: 400,
                    },
                    ".adyen-checkout__input--focus": {
                      border: `2px solid ${palette.grey[700]}`,
                      boxShadow: "none",
                      ":hover": {
                        border: `2px solid ${palette.grey[700]}`,
                      },
                    },
                    ".adyen-checkout__label--focused .adyen-checkout__label__text":
                      {
                        color: palette.grey[600],
                        "&.adyen-checkout__label__text--error": {
                          color: palette.error.main,
                        },
                        "&.zipcode-error": {
                          color: palette.error.main,
                        },
                      },
                    ".zipcode-error": {
                      color: palette.error.main,
                    },
                    "input.zipcode-error": {
                      border: `1px solid ${palette.error.main}`,
                      color: palette.grey[700],
                    },
                    ".adyen-checkout__label__text--error, .adyen-checkout__error-text":
                      {
                        color: palette.error.main,
                      },
                  }),
            })}
          />
        )}

        {zipErrorMessage && (
          <Typography
            variant="bodySmall"
            color={mode === "dark" ? "tints.pink.dark" : "error.main"}
            sx={{ textAlign: "left", width: "100%", ml: 8, mt: 1 }}
          >
            Please enter a valid zip code
          </Typography>
        )}

        {isCatchPassFlow && (
          <>
            {mode === "dark" ? (
              <Typography
                variant="bodySmall"
                color="tints.purple.main"
                mt={5.5}
              >
                By continuing, you agree to have Catch place a temporary hold on
                your card for verification purposes. The hold will be released
                within minutes.
              </Typography>
            ) : (
              <Box mt={5.5} mb={3}>
                <HoldAuthorization />
              </Box>
            )}
          </>
        )}
      </Stack>

      {displayButton && (
        <BankButton
          fullWidth
          onClick={handleSaveCard}
          startIcon={mode === "dark" ? undefined : <LockIcon />}
          loading={isCatchPassFlow && isProcessingLinkUpdate ? true : undefined}
          sx={{
            mt: 6,
            ...(isCatchPassFlow && {
              "&.MuiButton-containedBankButtonBackground": {
                mt: {
                  zero: 8,
                  md: 11,
                },
                backgroundColor: "primary.main",
                "&:hover, &:active": {
                  backgroundColor: "primary.main",
                },
              },
            }),
          }}
        >
          {saveCardButtonText}
        </BankButton>
      )}
    </Stack>
  );
};

export default AdyenCardInput;
