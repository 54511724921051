import React from "react";

import { Avatar, Card, CardMedia, Stack, Typography } from "@mui/material";

import { useEnv } from "~common/contexts/env-context";
import { useMerchantAssetsUrl } from "~common/hooks/asset-hooks";
import { MerchantCardInfo } from "~common/services";
import { useTracking } from "~common/tracking";
import { navigateWithUserParams } from "~common/utils/navigation";
import { getUtmParams } from "~src/utils/getUtmsParams";

type ExploreMerchantProps = {
  merchant: MerchantCardInfo;
  utmCampaign: string;
  categoryName?: string;
};

const ExploreMerchant: React.VFC<ExploreMerchantProps> = ({
  merchant,
  utmCampaign,
  categoryName,
}) => {
  const { APP_ENVIRONMENT, APP_STAGE } = useEnv();
  const { trackEvent } = useTracking();
  const href = `https://${merchant.url}/?${getUtmParams(utmCampaign)}`;
  const merchantAssetsUrl = useMerchantAssetsUrl(merchant.id);
  const bgImageUrl = `${merchantAssetsUrl}/shop_background_normal.png`;
  const badgeUrl = `${merchantAssetsUrl}/shop_badge.png`;

  const handleClick = () => {
    trackEvent("Shop ExploreMerchant clicked", {
      merchant: merchant.name,
      category: categoryName,
    });
  };

  return (
    <Stack
      spacing={2}
      component="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e: React.SyntheticEvent) =>
        navigateWithUserParams(e, APP_ENVIRONMENT, APP_STAGE, handleClick)
      }
      sx={{
        ".MuiCardMedia-root": {
          transition: "transform 0.5s",
        },
        "&:hover .MuiCardMedia-root": {
          transform: "scale(1.05)",
        },
      }}
    >
      <Card
        elevation={0}
        sx={({ shape }) => ({
          borderRadius: `${shape.borderRadius * 2}px`,
          position: "relative",
        })}
      >
        <CardMedia component="img" height="240" image={bgImageUrl} alt="" />

        <Avatar
          src={badgeUrl}
          alt={`${merchant.name} merchant logo`}
          sx={{
            width: 48,
            height: 48,
            position: "absolute",
            left: 16,
            bottom: 16,
          }}
        />
      </Card>

      <Stack>
        <Typography
          variant="h3"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {merchant.name}
        </Typography>

        <Typography
          variant="bodyRegular"
          color="grey.600"
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {merchant.rewards_rate
            ? `Earn ${Math.round(merchant.rewards_rate * 100)}% minimum`
            : null}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ExploreMerchant;
