import React from "react";
import { NavLink } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";

import InfoCard from "../shared/InfoCard";
import RewardAmountBadge from "../shared/RewardAmountBadge";

type CatchCardBenefitsProps = {
  fullWidth?: boolean;
};

const CatchCardBenefits: React.VFC<CatchCardBenefitsProps> = ({
  fullWidth,
}) => {
  const { trackEvent } = useTracking();

  return (
    <InfoCard
      heading="Maximize your rewards 🚀"
      cta={
        <NavLink
          to="/home"
          onClick={() => trackEvent("Catch Card Explore brands Clicked")}
        >
          <PrimaryButton
            sx={{
              width: {
                zero: "100%",
                md: fullWidth ? "auto" : "100%",
              },
            }}
          >
            Explore brands
          </PrimaryButton>
        </NavLink>
      }
    >
      <Stack
        sx={{
          rowGap: 4,
          ...(fullWidth && {
            flexDirection: {
              md: "row",
            },
            gap: {
              md: 8,
            },
          }),
        }}
      >
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          sx={
            fullWidth
              ? {
                  width: {
                    md: "50%",
                  },
                }
              : {}
          }
        >
          <RewardAmountBadge percent={15} qualifier="Up to" />

          <Typography variant="bodyRegular">
            When you pay with your Catch card at featured brands.
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          sx={
            fullWidth
              ? {
                  width: {
                    md: "50%",
                  },
                }
              : {}
          }
        >
          <RewardAmountBadge
            percent={1.5}
            bgcolor="tints.purple.light"
            color="tertiary.main"
          />

          <Typography variant="bodyRegular">
            Catch cash on every purchase.
          </Typography>
        </Stack>
      </Stack>
    </InfoCard>
  );
};

export default CatchCardBenefits;
