import React from "react";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import LabeledSection from "~src/components/layout/LabeledSection";
import useRewardPoolEntries from "~src/hooks/services/useRewardPoolEntries";
import useRewardPoolEvents from "~src/hooks/services/useRewardPoolEvents";

import ActivityFeedSkeleton from "../ActivityFeedSkeleton";
import CreditExchangeTable from "./CreditExchangeTable";

type MyExchangeSectionProps = {
  pageLoading: boolean;
};

const MyExchangeSection: React.VFC<MyExchangeSectionProps> = ({
  pageLoading,
}) => {
  const {
    rewardPoolEntries,
    loading: loadingEntries,
    error: entriesError,
  } = useRewardPoolEntries();
  const {
    rewardPoolEvents,
    loading: loadingEvents,
    error: eventsError,
  } = useRewardPoolEvents();

  if (
    !pageLoading &&
    !loadingEntries &&
    !loadingEvents &&
    (!rewardPoolEntries || !rewardPoolEntries.length) &&
    (!rewardPoolEvents || !rewardPoolEvents.length)
  ) {
    return null;
  }

  const error =
    entriesError || eventsError ? { entriesError, eventsError } : undefined;

  return (
    <LabeledSection heading="My exchange" loading={pageLoading}>
      <LoadingGuard {...{ error }}>
        {pageLoading || loadingEntries || loadingEvents ? (
          <ActivityFeedSkeleton />
        ) : (
          <CreditExchangeTable />
        )}
      </LoadingGuard>
    </LabeledSection>
  );
};

export default MyExchangeSection;
