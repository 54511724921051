import React from "react";

import { Box } from "@mui/material";

import SwiperWrapper from "~common/components/carousels/SwiperWrapper";
import type { MerchantCategory } from "~common/services";

import LabeledSection from "../layout/LabeledSection";
import ExploreAffiliate from "./ExploreAffiliate";
import ExploreMerchant from "./ExploreMerchant";

type ExploreCategoryProps = {
  category: MerchantCategory;
  utmCampaign: string;
};

const ExploreCategory: React.VFC<ExploreCategoryProps> = ({
  category,
  utmCampaign,
}) => (
  <LabeledSection heading={category.name}>
    <Box>
      <SwiperWrapper
        name={category.id}
        containerProps={{
          sx: {
            ".swiper": {
              overflow: {
                zero: "visible",
                sm: "hidden",
              },
            },
            button: {
              display: {
                zero: "none",
                sm: category.merchants.length > 4 ? "flex" : "none",
                md: category.merchants.length > 5 ? "flex" : "none",
              },
              top: category.id === "all-affiliate-merchants" ? 40 : 120,
              mx: {
                sm: -2,
                md: -6,
              },
            },
          },
        }}
        swiperProps={{
          grabCursor: true,
          navigation: true,
          slidesPerView: 2.08,
          spaceBetween: 16,
          breakpoints: {
            600: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
            840: {
              slidesPerView: 5,
              slidesPerGroup: 5,
            },
          },
        }}
        slides={category.merchants.map((merchant) =>
          category.id === "all-affiliate-merchants" ? (
            <ExploreAffiliate
              merchant={merchant}
              categoryName={category.name}
            />
          ) : (
            <ExploreMerchant
              merchant={merchant}
              categoryName={category.name}
              utmCampaign={utmCampaign}
            />
          )
        )}
      />
    </Box>
  </LabeledSection>
);

export default ExploreCategory;
