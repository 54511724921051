import React from "react";

import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { ButtonProps } from "@mui/material";

import { PrimaryButton } from "../controls/buttons";

type SwiperArrowProps = ButtonProps & {
  direction: "prev" | "next";
};

const SwiperArrow: React.VFC<SwiperArrowProps> = ({
  direction = "prev",
  ...props
}) => (
  <PrimaryButton
    sx={({ palette }) => ({
      width: 52,
      minWidth: 52,
      border: `1px solid ${palette.common.white}`,
      position: "absolute",
      transform: "translateY(-50%)",
      top: "50%",
      zIndex: 1,
      ...(direction === "prev"
        ? {
            left: 0,
          }
        : {
            right: 0,
          }),
      "..MuiButton-contained": {
        boxShadow: "0px 2px 4px rgba(35, 38, 57, 0.24)",
      },
      ".MuiButton-startIcon": {
        m: 0,
      },
      ".MuiSvgIcon-root": {
        width: 24,
        height: 24,
      },
      "&.swiper-button-disabled": {
        backgroundColor: "#e9a6d2",
        "&:hover": {
          backgroundColor: "#e9a6d2",
          "&:after": {
            content: "none",
          },
        },
      },
    })}
    startIcon={direction === "prev" ? <ArrowBackIcon /> : <ArrowForwardIcon />}
    {...props}
  />
);

export default SwiperArrow;
