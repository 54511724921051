import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTrackPageView } from "~common/tracking";
import CatchCardBanner from "~src/components/catch-card/CatchCardBanner";
import CatchCardBenefits from "~src/components/catch-card/CatchCardBenefits";
import LargePagePanel from "~src/components/layout/LargePagePanel";
import ActivityFeedSection from "~src/components/rewards/ActivityFeedSection";
import MyExchangeSection from "~src/components/rewards/exchange/MyExchangeSection";
import MyCreditsSection from "~src/components/rewards/MyCreditsSection";
import useFetchAnywhereCardForUser from "~src/hooks/fetching/useFetchAnywhereCardForUser";
import useUserData from "~src/hooks/services/useUserData";
import useUserRewards from "~src/hooks/services/useUserRewards";
import { selectCatchCard, selectCurrentUser } from "~src/store";

import RewardBoostNuxs from "../components/rewards/RewardBoostNuxs";

const Rewards: React.VFC = () => {
  const currentUser = useSelector(selectCurrentUser.data);
  const catchCard = useSelector(selectCatchCard);
  const { userRewards, loading: loadingUserRewards } = useUserRewards();
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  useTrackPageView("Home");
  useFetchAnywhereCardForUser();
  useUserData();

  useEffect(() => {
    if (!loadingUserRewards) {
      setPageLoading(false);
    }
  }, [loadingUserRewards]);

  return (
    <>
      <RewardBoostNuxs pageLoading={pageLoading} />

      <LargePagePanel width="medium">
        {currentUser?.is_eligible_for_catch_pass && <CatchCardBanner />}
        <MyCreditsSection pageLoading={pageLoading} />
        <MyExchangeSection pageLoading={pageLoading} />

        {!userRewards.length &&
          catchCard?.user_flow_status === "approved" &&
          catchCard.is_pin_set && <CatchCardBenefits fullWidth />}

        <ActivityFeedSection pageLoading={pageLoading} />
      </LargePagePanel>
    </>
  );
};

export default Rewards;
