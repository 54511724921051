import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Stack } from "@mui/material";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  DefaultDialog,
  ResponsiveDrawerDialog,
} from "~common/components/modals";
import { useUniqueId } from "~common/hooks/accessibility-hooks";
import { Reward } from "~common/services";
import { useTracking } from "~common/tracking";

import {
  selectDisplayRewardBoostDialog,
  selectFromRewardBoostEmail,
} from "../../store";
import {
  dismissDisplayRewardBoostModal,
  markDisplayRewardBoostModal,
} from "../../store/slices/user-slice";
import RewardBoostDetails from "./RewardBoostDetails";

interface RewardBoostModalProps {
  boostedRewards: Array<Reward>;
}

const RewardBoostModal: React.VFC<RewardBoostModalProps> = ({
  boostedRewards,
}) => {
  const dispatch = useDispatch();
  const displayRewardBoostDialog = useSelector(selectDisplayRewardBoostDialog);
  const { trackEvent } = useTracking();

  const modalHeadingId = useUniqueId("reward-boost-modal");
  const modalDescriptionId = useUniqueId("reward-boost-modal-description");
  const fromRewardBoostEmail = useSelector(selectFromRewardBoostEmail);

  useEffect(() => {
    // We do not want to open the reward boost modal if we are coming from the
    // reward boost email (designated by a UTM param) since the information is redundant
    // We also want to show the App Adoption NUX before we show any boosted rewards
    if (!fromRewardBoostEmail && !!boostedRewards.length) {
      trackEvent("Displaying reward boost modal", { boostedRewards });
      dispatch(markDisplayRewardBoostModal());
    }
  }, [boostedRewards, dispatch, fromRewardBoostEmail, trackEvent]);

  if (!boostedRewards.length) {
    return null;
  }

  const hasMultipleBoostedRewards = boostedRewards.length > 1;

  const handleClose = () => {
    trackEvent("Reward boost modal dismissed");
    dispatch(dismissDisplayRewardBoostModal());
  };

  const ModalContent: React.VFC = () => (
    <Stack
      direction="column"
      sx={{
        maxWidth: "100%",
        maxHeight: "100%",
      }}
    >
      <Box
        sx={{
          pb: hasMultipleBoostedRewards ? 4 : 8,
        }}
      >
        <Swiper
          grabCursor
          modules={[Pagination]}
          onSlideChange={() => {
            trackEvent("Reward boost modal slide changed");
          }}
          pagination={{
            bulletElement: "button",
            clickable: true,
            el: ".reward-boost-swiper-el",
          }}
        >
          {boostedRewards.map((boostedReward) => (
            <SwiperSlide key={boostedReward.reward_ids.join("+")}>
              <RewardBoostDetails
                boostedReward={boostedReward}
                handleClose={handleClose}
                displayCatchButton={!hasMultipleBoostedRewards}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {hasMultipleBoostedRewards && (
        <Box
          className="reward-boost-swiper-el"
          sx={({ palette }) => ({
            ".swiper-pagination-bullet": {
              background: palette.grey[300],
              opacity: 1,
              width: 8,
              height: 8,
              "&.swiper-pagination-bullet-active": {
                background: palette.grey[600],
                opacity: 1,
              },
            },
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            height: 8,
            mt: 4,
            mb: 8,
          })}
        />
      )}
    </Stack>
  );

  return hasMultipleBoostedRewards ? (
    <DefaultDialog
      open={displayRewardBoostDialog}
      onClose={handleClose}
      fullBleed
      ariaLabelledBy={modalHeadingId}
      ariaDescribedBy={modalDescriptionId}
      sx={({ breakpoints, shape }) => ({
        "& .MuiPaper-root.MuiDialog-paper": {
          pt: 26,
          borderRadius: `${shape.borderRadius * 2}px`,
          [breakpoints.down("sm")]: {
            left: "auto",
          },
        },
      })}
    >
      <ModalContent />
    </DefaultDialog>
  ) : (
    <ResponsiveDrawerDialog
      fullBleed
      name="rewardBoost"
      onClose={handleClose}
      open={displayRewardBoostDialog}
      sx={({ shape, palette }) => ({
        "&.MuiDialog-root .MuiBackdrop-root": {
          background: "transparent",
        },
        ".MuiDialog-paper": {
          width: "100%",
          borderRadius: `${shape.borderRadius * 4}px`,
          pt: 15,
          pb: 6,
        },
        "button[aria-label='Close dialog'] path": {
          fill: palette.grey[500],
        },
        ".MuiDrawer-paper > .MuiBox-root:last-of-type": {
          width: "100%",
          px: 4,
        },
      })}
    >
      {({ contentId, headingId }) => (
        <Box
          sx={{
            pt: {
              zero: 6,
              sm: 13,
            },
          }}
        >
          <ModalContent />
        </Box>
      )}
    </ResponsiveDrawerDialog>
  );
};

export default RewardBoostModal;
