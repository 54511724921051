import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/pagination";

import React from "react";

import { Box, BoxProps, useTheme } from "@mui/material";
import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

import SwiperArrow from "./SwiperArrow";

type SwiperWrapperProps = {
  name?: string;
  containerProps?: BoxProps;
  slides: React.ReactNode[];
  swiperProps?: SwiperProps;
};

const SwiperWrapper: React.FC<SwiperWrapperProps> = ({
  name,
  containerProps,
  slides,
  swiperProps,
}) => {
  const { palette } = useTheme();

  if (swiperProps?.navigation === true) {
    swiperProps.navigation = {
      prevEl: `.swiper-button-prev-${name || ""}`,
      nextEl: `.swiper-button-next-${name || ""}`,
    };
  }

  return (
    <Box
      {...containerProps}
      sx={{
        position: "relative",
        ".swiper-horizontal > .swiper-pagination-bullets": {
          bottom: 16,
        },
        ".swiper-pagination-bullet": {
          background: palette.grey[300],
          opacity: 1,
        },
        ".swiper-pagination-bullet-active": {
          background: palette.grey[600],
        },
        ...(containerProps?.sx ? containerProps.sx : {}),
      }}
    >
      <Swiper modules={[A11y, Navigation, Pagination]} {...swiperProps}>
        {slides.map((slide, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={i}>{slide}</SwiperSlide>
        ))}
      </Swiper>

      {typeof swiperProps?.navigation === "object" &&
        swiperProps?.navigation?.prevEl ===
          `.swiper-button-prev-${name || ""}` &&
        swiperProps?.navigation?.nextEl ===
          `.swiper-button-next-${name || ""}` && (
          <>
            <SwiperArrow
              direction="prev"
              className={`swiper-button-prev-${name || ""}`}
            />
            <SwiperArrow
              direction="next"
              className={`swiper-button-next-${name || ""}`}
            />
          </>
        )}
    </Box>
  );
};

SwiperWrapper.defaultProps = {
  name: "",
  containerProps: {},
  swiperProps: {},
};

export default SwiperWrapper;
