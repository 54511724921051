import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { css, Global } from "@emotion/react";
import { Box, Typography, useTheme } from "@mui/material";

import AdyenCardConnect from "~common/components/bank-linking/AdyenCardConnect";
import {
  setBankLinkingStep,
  setDisableAch,
} from "~common/store/slices/bankLinking-slice";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";

const PaymentLinking: React.VFC = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  usePaymentInstruments();

  useEffect(() => {
    dispatch(setBankLinkingStep("ConnectBank"));
    dispatch(setDisableAch(true));

    return () => {
      dispatch(setDisableAch(false));
    };
  }, [dispatch]);

  return (
    <Box bgcolor="tertiary.main" height="100%" overflow="auto" pt={4}>
      <Global
        styles={css`
          body {
            background: ${palette.tertiary.main};
          }
        `}
      />

      {/* TODO: Update fonts and spacing to work with SmallPagePanel updates in full onboarding UI refresh */}
      <SmallPagePanel
        icon={<></>}
        title={
          <Box fontWeight={900} color="common.white">
            Link your debit card.
          </Box>
        }
        subtitle={
          <Box color="common.white">
            The Catch card comes with a{" "}
            <Typography
              variant="inherit"
              component="span"
              color="tertiary.contrastText"
              fontWeight={700}
            >
              free digital debit card
            </Typography>{" "}
            linked to your existing debit card, so you can earn everywhere.
          </Box>
        }
        sx={{
          minHeight: "100%",
          "& > div": {
            flexGrow: 1,
          },
          "& > div > div:last-of-type": {
            flexGrow: 1,
            justifyContent: "space-between",
            pt: {
              zero: 1,
              md: 4,
            },
          },
        }}
      >
        <AdyenCardConnect inline mode="dark" />
      </SmallPagePanel>
    </Box>
  );
};

export default PaymentLinking;
