import React from "react";

import { Sell as SellIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { CatchCashIcon } from "~common/components/icons/vector";
import { MerchantPartnerType } from "~common/services";
import { formatPercent } from "~common/utils/numbers";

type MerchantRewardProps = {
  partnerType: MerchantPartnerType;
  rewardRate: number;
};

const MerchantReward: React.VFC<MerchantRewardProps> = ({
  partnerType,
  rewardRate,
}) => (
  <Stack
    direction="row"
    flexWrap="wrap"
    color={partnerType === "affiliate" ? "tertiary.main" : "primary.main"}
    sx={{ ".MuiSvgIcon-root": { width: 14 } }}
  >
    {partnerType === "standard" && <SellIcon fontSize="small" sx={{ mr: 1 }} />}

    {partnerType === "affiliate" && (
      <CatchCashIcon size="original" sx={{ mr: 1 }} />
    )}

    <Typography variant="bodySmall" mr={1} fontWeight={700}>
      Earn {formatPercent(rewardRate)}
    </Typography>

    <Typography variant="bodySmall" color="grey.700">
      {partnerType === "affiliate" ? "Catch Cash" : "store credit"}
    </Typography>
  </Stack>
);

export default MerchantReward;
