import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Box, Link, Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { PhoneInput } from "~common/components/controls/text-fields";
import { ResponsiveDrawerDialog } from "~common/components/modals";
import { useGetRewardCampaign } from "~common/services";
import { usePhoneUrlMapping } from "~common/services/phone-url-mapping";
import { useTransactionalSms } from "~common/services/sms";
import { useTracking } from "~common/tracking";
import { embeddedMessenger } from "~common/utils/iframe-messaging";
import {
  isValidUSPhoneNumber,
  removePhoneNumberFormatting,
} from "~common/utils/phone-number";
import { WEBFLOW_URL } from "~src/config";
import { getDevice } from "~src/utils/devices";
import {
  getRewardCampaignId,
  NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID,
} from "~src/utils/reward-campaigns";

const SignUp: React.VFC = () => {
  const device = getDevice();
  const [searchParams] = useSearchParams();
  const rewardCampaignId = searchParams.get(
    NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID
  );
  const { trackEvent, trackError } = useTracking();
  const { mutate: transactionalSms } = useTransactionalSms();
  const { mutate: phoneUrlMapping } = usePhoneUrlMapping();
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [params, setParams] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [singularLink, setSingularLink] = useState("");
  const [referrer, setReferrer] = useState("");

  const { data: reward } = useGetRewardCampaign({
    pathParams: {
      rewardCampaignId: getRewardCampaignId(rewardCampaignId),
    },
    lazy: !rewardCampaignId,
  });

  useEffect(() => {
    embeddedMessenger(WEBFLOW_URL).dispatch("CATCH_SIGNUP_LOAD");

    embeddedMessenger(WEBFLOW_URL, {
      CATCH_SIGNUP_OPEN: ({ data }) => {
        setReferrer((data?.referrer as string) || "");
        setSingularLink((data?.singularLink as string) || "");
        setOpen(true);
      },
    });
  }, []);

  useEffect(() => {
    const rewardCampaignIdParam =
      rewardCampaignId && reward
        ? `new_user_onboarding_reward_campaign_id=${rewardCampaignId}`
        : "";

    if (isValidUSPhoneNumber(phone)) {
      setParams(
        `?phoneNumber=${removePhoneNumberFormatting(phone)}&skipAuthPhone=true${
          rewardCampaignIdParam ? `&${rewardCampaignIdParam}` : ""
        }`
      );
    } else {
      setParams(rewardCampaignIdParam ? `?${rewardCampaignIdParam}` : "");
    }
  }, [phone, rewardCampaignId, reward]);

  const handleChange = () => {
    setPhone("");
    setIsComplete(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (device === "iphone") {
      embeddedMessenger(WEBFLOW_URL).dispatch("CATCH_SIGNUP_REDIRECT");
      return;
    }

    if (!isValidUSPhoneNumber(phone)) {
      setHasError(true);
      return;
    }

    trackEvent("External Sign Up SMS Submitted");
    setLoading(true);
    await handlePhoneUrlMapping();

    try {
      await transactionalSms({
        phone_number: `+1${removePhoneNumberFormatting(phone)}`,
        message_type: "ios_app_link",
        ...(singularLink && {
          data: {
            singular_link: singularLink,
          },
        }),
      });

      setLoading(false);
      setIsComplete(true);
      trackEvent("External Sign Up SMS Sent");
    } catch (err) {
      setLoading(false);
      setHasError(true);
      trackError("External Sign Up", "Send SMS ", { error: err });
    }
  };

  const handleWebClick = async () => {
    trackEvent("External Sign Up Continue on web Clicked");

    if (params) {
      await handlePhoneUrlMapping();
    }
  };

  const handleClose = () => {
    setOpen(false);
    embeddedMessenger(WEBFLOW_URL).dispatch("CATCH_SIGNUP_CLOSE");
  };

  const handlePhoneUrlMapping = async () => {
    try {
      await phoneUrlMapping({
        phone_number: `+1${removePhoneNumberFormatting(phone)}`,
        url: referrer,
      });
    } catch (err) {
      trackError("External Sign Up", "Phone Url Mapping ", { error: err });
    }
  };

  return (
    <ResponsiveDrawerDialog
      open={open}
      onClose={handleClose}
      sx={{
        ".MuiDrawer-paper > .MuiBox-root:last-of-type": {
          px: 4,
        },
      }}
    >
      {({ headingId, contentId }) => (
        <Stack
          spacing={6}
          id={contentId}
          component="form"
          onSubmit={handleSubmit}
          sx={{
            px: 3,
            pt: {
              zero: 6,
              sm: 0,
            },
            pb: 1,
          }}
        >
          {isComplete ? (
            <>
              <Stack spacing={4}>
                <Typography id={headingId} variant="h2">
                  Did you get our text?
                </Typography>

                <Typography variant="bodyRegular">
                  A text message with a link to the app store has been sent to{" "}
                  {phone}.{" "}
                  <Link component="button" onClick={handleChange}>
                    Change
                  </Link>
                </Typography>
              </Stack>

              <Box pt={2}>
                <PrimaryButton type="submit" loading={loading} fullWidth>
                  Resend Code
                </PrimaryButton>
              </Box>

              <Link
                alignSelf="center"
                href={`https://app.getcatch.com/u/${params}`}
                onClick={handleWebClick}
                target="_top"
              >
                <Typography variant="bodyRegular" fontWeight="500">
                  Continue on web
                </Typography>
              </Link>
            </>
          ) : (
            <>
              <Stack spacing={4}>
                <Typography
                  id={headingId}
                  variant="h2"
                  sx={{
                    fontSize: 32,
                    fontWeight: 900,
                    lineHeight: 1.2,
                    letterSpacing: "-0.04em",
                  }}
                >
                  Ready to claim your Catch Cash?
                </Typography>

                <Typography variant="h2" component="h3">
                  Earn up to{" "}
                  <Typography
                    variant="inherit"
                    component="strong"
                    color="tertiary.main"
                  >
                    $30 in welcome rewards
                  </Typography>{" "}
                  to use at
                  {reward?.merchant_name
                    ? ` ${reward.merchant_name} and`
                    : ""}{" "}
                  {reward?.merchant_name ? "more of " : ""}the hottest brands.
                </Typography>
              </Stack>

              {device !== "iphone" && (
                <Box pt={1.5}>
                  <PhoneInput
                    label={undefined}
                    value={phone}
                    updateValue={setPhone}
                    helperText="Phone number"
                    error={hasError}
                    id="phone-input"
                    aria-describedby="phone-input-helper-text"
                    inputProps={{
                      "aria-label": "Phone number",
                    }}
                  />
                </Box>
              )}

              <Box pt={3}>
                <PrimaryButton
                  type={device === "android" ? undefined : "submit"}
                  loading={loading}
                  fullWidth
                  onClick={device === "android" ? handleWebClick : undefined}
                  href={
                    device === "android"
                      ? `https://app.getcatch.com/u/${params}`
                      : undefined
                  }
                >
                  Claim in the app
                </PrimaryButton>
              </Box>

              {device !== "android" && (
                <Link
                  alignSelf="center"
                  href={`https://app.getcatch.com/u/${params}`}
                  onClick={handleWebClick}
                  target="_top"
                >
                  <Typography variant="bodyRegular" fontWeight="500">
                    Continue on web
                  </Typography>
                </Link>
              )}

              {!device && (
                <Typography
                  variant="bodySmall"
                  color="grey.500"
                  fontSize={10}
                  lineHeight={1.3}
                >
                  By providing your number, you agree to receive a one-time
                  Catch SMS transactional message. See{" "}
                  <Link href="https://www.getcatch.com/terms" target="_top">
                    Terms
                  </Link>{" "}
                  for more info. Message and data rates may apply.
                </Typography>
              )}
            </>
          )}
        </Stack>
      )}
    </ResponsiveDrawerDialog>
  );
};

export default SignUp;
