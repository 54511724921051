import React from "react";

import { Box } from "@mui/material";

import SwiperWrapper from "~common/components/carousels/SwiperWrapper";

import LabeledSection from "../layout/LabeledSection";
import ExploreMerchantSkeleton from "./ExploreMerchantSkeleton";

type ExploreCategoriesSkeletonProps = {
  rows?: number;
  columns?: number;
  showHeadingSkeleton?: boolean;
};

const ExploreCategoriesSkeleton: React.VFC<ExploreCategoriesSkeletonProps> = ({
  rows = 7,
  columns = 5,
}) => (
  <>
    {[...Array(rows).keys()].map((i) => (
      <LabeledSection key={i} heading="" loading>
        <Box>
          <SwiperWrapper
            containerProps={{
              sx: {
                ".swiper": {
                  overflow: {
                    zero: "visible",
                    sm: "hidden",
                  },
                },
              },
            }}
            swiperProps={{
              slidesPerView: 2.08,
              spaceBetween: 16,
              breakpoints: {
                600: { slidesPerView: 4 },
                840: { slidesPerView: 5 },
              },
            }}
            slides={[...Array(columns).keys()].map((j) => (
              <ExploreMerchantSkeleton key={j} />
            ))}
          />
        </Box>
      </LabeledSection>
    ))}
  </>
);

export default ExploreCategoriesSkeleton;
