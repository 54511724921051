import React from "react";

import { Box, Stack } from "@mui/material";

import { useAffiliateBackgroundUrl } from "~common/hooks/asset-hooks";
import { MerchantCardInfo } from "~common/services";
import { useTracking } from "~common/tracking";

import MerchantReward from "../shared/MerchantReward";

type ExploreAffiliateProps = {
  merchant: MerchantCardInfo;
  categoryName?: string;
};

const ExploreAffiliate: React.VFC<ExploreAffiliateProps> = ({
  merchant,
  categoryName,
}) => {
  const { trackEvent } = useTracking();
  const backgroundUrl = useAffiliateBackgroundUrl(merchant.id);

  const handleClick = () => {
    trackEvent("Shop ExploreAffiliate clicked", {
      merchant: merchant.name,
      category: categoryName,
    });
  };

  return (
    <Stack
      spacing={2}
      component="a"
      href={merchant.url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      sx={{
        img: {
          transition: "transform 0.5s",
        },
        "&:hover img": {
          transform: "scale(1.05)",
        },
      }}
    >
      <Box
        sx={({ palette, shape }) => ({
          height: 82,
          background: `${palette.grey[200]} url(${backgroundUrl}) no-repeat`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          border: `1px solid ${palette.grey[300]}`,
          borderRadius: `${shape.borderRadius * 2}px`,
          overflow: "hidden",
        })}
        aria-label={merchant.name}
      />

      <MerchantReward
        partnerType="affiliate"
        rewardRate={merchant.rewards_rate}
      />
    </Stack>
  );
};

export default ExploreAffiliate;
