import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

import { ReferralIcon } from "~common/components/icons/vector";
import LoadingGuard from "~common/components/loading/LoadingGuard";
import { REFERRAL_BONUS, SIGN_UP_BONUS } from "~common/constants/bonuses";
import { useTrackPageView } from "~common/tracking";
import { formatUSDate } from "~common/utils/dates";
import LandingPagePanel from "~src/components/landing/LandingPagePanel";
import YouCanStillEarnCredit from "~src/components/landing/YouCanStillEarnCredit";
import useReferral from "~src/hooks/services/useReferral";
import useAlertModal from "~src/hooks/useAlertModal";
import { selectIsAuthed } from "~src/store";
import { setShowAppAdoptionNux } from "~src/store/slices/nuxs-slice";
import { setClaimedReferralCode } from "~src/store/slices/user-slice";

const LandingReferral: React.VFC = () => {
  useTrackPageView("Referral Landing Page");

  const { referralCode } = useParams();
  const { referral, loading, error } = useReferral(referralCode || "");
  const dispatch = useDispatch();
  const isAuthed = useSelector(selectIsAuthed);
  const setAlertModal = useAlertModal();

  useEffect(() => {
    dispatch(setShowAppAdoptionNux(false));
  }, [dispatch]);

  useEffect(() => {
    // Save referral info to store for claiming after authentication.
    if (referral && referralCode && !error && !isAuthed) {
      dispatch(setClaimedReferralCode(referralCode));
    }
  }, [referral, referralCode, error, dispatch, isAuthed]);

  if (isAuthed) {
    setAlertModal.existingUserCantClaimReferral();

    return <Navigate to="/home" replace />;
  }

  const referrerName = referral?.first_name || "me";

  const referrerJoinDate = referral?.create_date;

  return (
    <Box bgcolor="tertiary.main" color="common.white">
      <LandingPagePanel ctaCopy="Continue with Catch">
        <LoadingGuard {...{ loading }}>
          {error ? (
            <>
              <Typography variant="h1" sx={{ mb: 4 }}>
                Looks like that code isn&apos;t valid, but hey...
              </Typography>
              <YouCanStillEarnCredit />
            </>
          ) : (
            <>
              <Stack spacing={4} pb={2}>
                <Box>
                  <Typography variant="h2">
                    Join {referrerName} on Catch
                  </Typography>

                  {referrerJoinDate && (
                    <Typography variant="bodyRegular">
                      Member since {formatUSDate(referrerJoinDate)}
                    </Typography>
                  )}
                </Box>

                <Typography variant="h1">
                  Earn an extra{" "}
                  <Typography
                    variant="h1"
                    component="span"
                    fontWeight="900"
                    color="tertiary.contrastText"
                  >
                    ${SIGN_UP_BONUS + REFERRAL_BONUS} credit
                  </Typography>{" "}
                  on your first purchase
                </Typography>

                <Stack
                  sx={({ palette, shape }) => ({
                    background: palette.primaryBackground,
                    color: palette.tertiary.main,
                    borderRadius: `${shape.borderRadius * 2}px`,
                    px: 3,
                    py: 2,
                  })}
                >
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ justifyContent: "center" }}
                  >
                    <ReferralIcon size="icon" />
                    <Typography variant="h4">
                      {referrerName} hooked you up!
                    </Typography>
                  </Stack>
                  <Typography variant="bodySmall">
                    You&apos;ll earn an extra ${SIGN_UP_BONUS + REFERRAL_BONUS}{" "}
                    back on your first order (instead of the usual $
                    {SIGN_UP_BONUS} bonus).
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </LoadingGuard>
      </LandingPagePanel>
    </Box>
  );
};

export default LandingReferral;
