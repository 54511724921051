import React, { useEffect, useRef } from "react";

import {
  Cancel as CancelIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Box, IconButton, InputBase, Stack } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import useMatchesMedia from "~common/hooks/useMatchesMedia";

type SearchInputProps = {
  value?: string;
  onChange: (value: string) => void;
  onFocus: () => void;
  isActive: boolean;
};

const SearchInput: React.VFC<SearchInputProps> = ({
  value,
  onChange,
  onFocus,
  isActive,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMatchesMedia("sm");

  useEffect(() => {
    if (isMobile) {
      inputRef.current && inputRef.current.focus();
    }
  }, [isMobile]);

  return (
    <InputBase
      fullWidth
      id="global-search"
      inputRef={inputRef}
      onChange={(e) => onChange(e.target.value)}
      onFocus={onFocus}
      placeholder="Search brands"
      value={value}
      sx={({ palette, shape, zIndex }) => ({
        background: isActive ? palette.common.white : palette.grey[200],
        px: 3,
        py: {
          zero: 2.25,
          sm: 0.75,
        },
        borderRadius: `${shape.borderRadius * 7.5}px`,
        position: "relative",
        zIndex: isActive ? zIndex.tooltip + 1 : undefined,
      })}
      startAdornment={
        <Stack component="label" htmlFor="global-search">
          <Box sx={visuallyHidden}>Search</Box>

          <SearchIcon
            fontSize="large"
            sx={({ palette }) => ({
              mr: 2,
              color: palette.grey[700],
            })}
          />
        </Stack>
      }
      endAdornment={
        value?.length ? (
          <IconButton aria-label="Clear search" onClick={(e) => onChange("")}>
            <CancelIcon
              sx={({ palette }) => ({
                "&.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root": {
                  fontSize: 20,
                  color: palette.grey[300],
                  "&:hover": {
                    color: palette.grey[400],
                  },
                },
              })}
            />
          </IconButton>
        ) : undefined
      }
    />
  );
};

export default SearchInput;
