import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, BoxProps } from "@mui/material";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import HighlightList from "~common/components/highlights/HighlightList";
import LoadingGuard from "~common/components/loading/LoadingGuard";
import useHideIntercom from "~common/hooks/useHideIntercom";
import useMatchesMedia from "~common/hooks/useMatchesMedia";
import {
  HighlightWithState,
  RewardCampaignResponseData,
  useClaimRewardCampaign,
  useGetRewardCampaign,
  useUpdateHighlightStatus,
} from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useTracking } from "~common/tracking";
import { CloseReason } from "~common/utils/modal-utils";
import useCurrentUser from "~src/hooks/services/useCurrentUser";
import useHighlights from "~src/hooks/services/useHighlights";
import { highlightsActions } from "~src/store/slices/services/highlights-slice";
import { userRewardsActions } from "~src/store/slices/services/userRewards-slice";

const HighlightsSection: React.VFC = () => {
  const dispatch = useDispatch();
  const isMobile = useMatchesMedia("sm");
  const { highlights, loading, error } = useHighlights();
  const { patchTrackingContext, trackError, trackEvent } = useTracking();
  const { refetch: fetchRewardCampaign } = useGetRewardCampaign({
    lazy: true,
  });
  const { mutate: updateHighlightStatus } = useUpdateHighlightStatus();
  const { mutate: claimRewardCampaign } = useClaimRewardCampaign();
  const { currentUser, loading: loadingCurrentUser } = useCurrentUser();
  const [hideIntercom, setHideIntercom] = useState<boolean>(false);
  const [rewardCampaign, setRewardCampaign] =
    useState<RewardCampaignResponseData | null>(null);

  const handleRead = async (activeHighlight: HighlightWithState) => {
    setHideIntercom(true);
    handleSetHighlightRead({ ...activeHighlight });

    trackEvent("Highlight Opened", {
      highlightId: activeHighlight.id,
      highlightCompleted: activeHighlight.status === "completed",
      rewardCampaignId: activeHighlight.reward_campaign_code.replace("rc-", ""),
    });

    try {
      if (activeHighlight.status === "unread") {
        await updateHighlightStatus(
          { status: "read" },
          {
            pathParams: {
              highlightId: activeHighlight.id,
            },
          }
        );
      }

      if (
        activeHighlight.status !== "completed" &&
        activeHighlight.reward_campaign_code
      ) {
        await handleSetRewardCampaign(activeHighlight.reward_campaign_code);
      }
    } catch (rawError) {
      trackError("HighlightsSection", "Failure reading highlight", {
        error: convertRawServiceError(rawError),
      });
    }
  };

  const handleClaim = async (activeHighlight: HighlightWithState) => {
    const rewardCampaignId = activeHighlight.reward_campaign_code.replace(
      "rc-",
      ""
    );
    handleSetHighlightClaiming({ ...activeHighlight });

    try {
      await claimRewardCampaign(
        { reward_campaign_claim_source: "user_portal_highlight" },
        {
          pathParams: {
            rewardCampaignId,
          },
        }
      );

      await updateHighlightStatus(
        { status: "completed" },
        {
          pathParams: {
            highlightId: activeHighlight.id,
          },
        }
      );

      handleSetHighlightCompleted({ ...activeHighlight });
      handleRefreshRewards();

      trackEvent("Highlight Reward Claimed", {
        highlightId: activeHighlight.id,
        rewardCampaignId,
      });
    } catch (rawError) {
      const convertedError = convertRawServiceError(rawError);
      handleSetHighlightError({ ...activeHighlight }, convertedError.message);
      trackError("HighlightsSection", "Failure claming highlight reward", {
        error: convertedError,
      });
    }
  };

  const handleRedirect = (activeHighlight: HighlightWithState) => {
    trackEvent("Highlight Reward Redirect Clicked", {
      highlightId: activeHighlight.id,
      rewardCampaignId: activeHighlight.reward_campaign_code.replace("rc-", ""),
    });
  };

  const handleClose = (
    activeHighlight: HighlightWithState,
    event: unknown,
    reason?: CloseReason
  ) => {
    setHideIntercom(false);

    trackEvent("Highlight Closed", {
      highlightId: activeHighlight.id,
      reason,
    });
  };

  const handleSetHighlightRead = (activeHighlight: HighlightWithState) => {
    if (activeHighlight.status === "unread") {
      activeHighlight.status = "read";

      dispatch(
        highlightsActions.manualSet([
          activeHighlight,
          ...highlights.filter(
            (highlight) => highlight.id !== activeHighlight.id
          ),
        ])
      );
    } else {
      activeHighlight.rewardCampaignStatus = "unclaimed";
      activeHighlight.error = "";

      dispatch(
        highlightsActions.manualSet(
          highlights.map((highlight) =>
            highlight.id === activeHighlight.id ? activeHighlight : highlight
          )
        )
      );
    }
  };

  const handleSetHighlightClaiming = (activeHighlight: HighlightWithState) => {
    activeHighlight.rewardCampaignStatus = "claiming";

    dispatch(
      highlightsActions.manualSet(
        highlights.map((highlight) =>
          highlight.id === activeHighlight.id ? activeHighlight : highlight
        )
      )
    );
  };

  const handleSetHighlightCompleted = (activeHighlight: HighlightWithState) => {
    activeHighlight.status = "completed";
    activeHighlight.rewardCampaignStatus = "claimed";

    dispatch(
      highlightsActions.manualSet(
        highlights.map((highlight) =>
          highlight.id === activeHighlight.id ? activeHighlight : highlight
        )
      )
    );
  };

  const handleSetHighlightError = (
    activeHighlight: HighlightWithState,
    errorMessage: string
  ) => {
    activeHighlight.error = errorMessage;

    dispatch(
      highlightsActions.manualSet(
        highlights.map((highlight) =>
          highlight.id === activeHighlight.id ? activeHighlight : highlight
        )
      )
    );
  };

  const handleSetRewardCampaign = async (rewardCampaignCode: string) => {
    const fetchedRewardCampaign = await fetchRewardCampaign({
      pathParams: {
        rewardCampaignId: rewardCampaignCode.replace("rc-", ""),
      },
    });

    setRewardCampaign(fetchedRewardCampaign);
  };

  const handleRefreshRewards = () => {
    dispatch(userRewardsActions.setStatus("requested"));
  };

  useEffect(() => {
    if (highlights.length) {
      patchTrackingContext({ viewedHighlights: true });

      trackEvent("Highlights Viewed", {
        highlightIds: highlights.map((highlight) => highlight.id),
        rewardCampaignIds: highlights.map((highlight) =>
          highlight.reward_campaign_code.replace("rc-", "")
        ),
      });
    }
  }, [highlights, patchTrackingContext, trackEvent]);

  useHideIntercom(hideIntercom && isMobile);

  if (!loading && !highlights.length) {
    return null;
  }

  return (
    <Box
      sx={{
        maxWidth: "100%",
        position: "relative",
        alignSelf: "flex-start",
        ".swiper": {
          overflow: "visible",
        },
        ".swiper-slide": {
          width: "auto",
        },
      }}
    >
      <LoadingGuard {...{ error }}>
        <Swiper slidesPerView="auto" modules={[FreeMode]} freeMode>
          <SwiperSlide>
            <HighlightList
              highlights={highlights}
              loading={loading || loadingCurrentUser}
              onClaim={handleClaim}
              onRead={handleRead}
              onRedirect={handleRedirect}
              onClose={handleClose}
              rewardCampaign={rewardCampaign}
              hideAppAdoptionHighlight={
                currentUser?.ios_app_downloaded ||
                currentUser?.android_app_downloaded
              }
            />
          </SwiperSlide>
        </Swiper>
      </LoadingGuard>
    </Box>
  );
};

export default HighlightsSection;
