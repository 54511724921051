import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Reward, useBatchUpdateNux } from "~common/services";
import useUserData from "~src/hooks/services/useUserData";
import useUserRewards from "~src/hooks/services/useUserRewards";
import { selectBoostedRewards, selectNuxs } from "~src/store";
import { getDevice } from "~src/utils/devices";

import RewardBoostModal from "./RewardBoostModal";

interface RewardBoostNuxsProps {
  pageLoading: boolean;
}

const RewardBoostNuxs: React.FC<RewardBoostNuxsProps> = ({ pageLoading }) => {
  const { loading: loadingUserRewards } = useUserRewards();
  const boostedRewards = useSelector(selectBoostedRewards);

  const { mutate: batchUpdateNux } = useBatchUpdateNux();
  const nuxs = useSelector(selectNuxs);
  const [boostedRewardsToDisplay, setBoostedRewardsToDisplay] = useState<
    Reward[]
  >([]);
  const hasUpdatedNux = useRef<boolean>(false);
  const { userData, loading: loadingUserData } = useUserData();
  const device = getDevice();

  // useEffect to filter out already seen rewardBoost nuxs, display the RewardBoost modal, and update
  // the nuxs on the user object
  useEffect(() => {
    if (
      !boostedRewards.length ||
      !nuxs ||
      loadingUserData ||
      loadingUserRewards
    ) {
      return;
    }

    const nuxsToUpdate: string[] = [];

    // Check to see if there are any reward boosts in NUX params that the user has in their rewards
    // and that they have not already seen
    const filteredRewards = boostedRewards.filter(
      (boostedReward) =>
        boostedReward.associated_reward_boost &&
        nuxs?.includes(boostedReward.associated_reward_boost.id)
    );
    setBoostedRewardsToDisplay(filteredRewards);

    const rewardBoostIds = filteredRewards.reduce((filtered, boostedReward) => {
      if (boostedReward.associated_reward_boost) {
        filtered.push(boostedReward.associated_reward_boost.id);
      }
      return filtered;
    }, [] as string[]);

    const updateUserNux = async () => {
      const updateNux = [...nuxsToUpdate, ...rewardBoostIds];
      if (!updateNux.length || hasUpdatedNux.current) {
        return;
      }
      hasUpdatedNux.current = true;
      await batchUpdateNux({
        nuxs: updateNux,
      });
    };

    void updateUserNux();
  }, [
    batchUpdateNux,
    boostedRewards,
    device,
    loadingUserData,
    loadingUserRewards,
    nuxs,
    userData?.has_redeemed_rewards,
  ]);

  if (pageLoading || !boostedRewards.length) {
    return null;
  }

  return <RewardBoostModal boostedRewards={boostedRewardsToDisplay} />;
};

export default RewardBoostNuxs;
