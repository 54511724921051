import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { NuxName } from "~common/services";

type NuxsState = {
  nuxs: NuxName[] | null;
  showAppAdoptionNux: boolean;
};

const initialState: NuxsState = {
  nuxs: null,
  showAppAdoptionNux: true,
};

const slice = createSlice({
  name: "nuxs",
  initialState,
  reducers: {
    setNuxs: (state, action: PayloadAction<NuxName[]>) => {
      state.nuxs = action.payload;
    },
    setShowAppAdoptionNux: (state, action: PayloadAction<boolean>) => {
      state.showAppAdoptionNux = action.payload;
    },
  },
});

export const { setNuxs, setShowAppAdoptionNux } = slice.actions;
export default slice.reducer;
