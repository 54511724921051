import React from "react";

import { Box, Stack, SxProps, Theme } from "@mui/material";

// This component provides the base UI for large pages.

type LargePagePanelProps = {
  width?: "full" | "medium";
  sx?: SxProps<Theme>;
};

const LargePagePanel: React.FC<LargePagePanelProps> = ({
  width,
  sx,
  children,
}) => (
  <Box sx={sx}>
    <Stack
      sx={({ breakpoints }) => ({
        px: 6,
        pt: 8,
        pb: 24,
        gap: 16,
        width: "100%",
        maxWidth:
          width === "medium"
            ? "var(--medium-page-panel-max-width)"
            : "var(--large-page-panel-max-width)",
        minHeight: "calc(100vh - var(--navbar-height) - 1px)",
        margin: "0 auto",
        [breakpoints.up("md")]: {
          pt: 12,
        },
      })}
    >
      <Stack
        sx={{
          gap: {
            zero: 8,
            sm: 12,
          },
          flexGrow: 1,
        }}
      >
        {children}
      </Stack>
    </Stack>
  </Box>
);

LargePagePanel.defaultProps = {
  width: "full",
  sx: {},
};

export default LargePagePanel;
