import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

const selectAlertModal = createSelector(
  (state: RootState) => state.core.alertModal,
  (alertModal) => alertModal
);

const selectIsLoading = createSelector(
  (state: RootState) => state.core.isLoading,
  (isLoading) => isLoading
);

const selectMenuOpen = createSelector(
  (state: RootState) => state.core.menuOpen,
  (menuOpen) => menuOpen
);

const selectPrefill = (state: RootState) => state.core.prefill;

const selectFromRewardBoostEmail = (state: RootState) =>
  state.core.fromRewardBoostEmail;

const selectIsMobileApp = (state: RootState) => state.core.isMobileApp;

export {
  selectAlertModal,
  selectFromRewardBoostEmail,
  selectIsLoading,
  selectIsMobileApp,
  selectMenuOpen,
  selectPrefill,
};
