import { useGet } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  ExplorePublicMerchantCategoriesQueryParams,
  ExplorePublicMerchantCategoriesResponseData,
  GetPublicMerchantByPublicKeyPathParams,
  MerchantSearchQueryParams,
  MerchantSearchResponseData,
  PublicMerchantByPublicKeyResponseData,
  PublicMerchantListResponseData,
  RecommendedMerchantCategoryQueryParams,
  RecommendedMerchantCategoryResponseData,
} from "./types/merchants-types";

const useGetPublicMerchantByPublicKey = (
  options: {
    pathParams?: GetPublicMerchantByPublicKeyPathParams;
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    PublicMerchantByPublicKeyResponseData,
    ErrorResponseData,
    undefined,
    GetPublicMerchantByPublicKeyPathParams
  >({
    path: ({ publicKey }) =>
      `merchants-svc/merchants/public_keys/${publicKey}/public`,
    pathParams: options.pathParams,
    lazy: options.lazy,
  });

const useGetPublicMerchantList = (
  options: { lazy?: boolean } = {
    lazy: false,
  }
) =>
  useGet<
    PublicMerchantListResponseData,
    ErrorResponseData,
    undefined,
    undefined
  >({
    path: () => "merchants-svc/merchants/public",
    lazy: options.lazy,
  });

const useGetExplorePublicMerchantCategories = (
  options: {
    queryParams?: ExplorePublicMerchantCategoriesQueryParams;
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    ExplorePublicMerchantCategoriesResponseData,
    ErrorResponseData,
    ExplorePublicMerchantCategoriesQueryParams,
    undefined
  >({
    path: () => "merchants-svc/categories/merchants/explore",
    queryParams: options.queryParams,
    lazy: options.lazy,
  });

const useGetRecommendedMerchants = (
  options: {
    queryParams?: RecommendedMerchantCategoryQueryParams;
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    RecommendedMerchantCategoryResponseData,
    ErrorResponseData,
    RecommendedMerchantCategoryQueryParams,
    undefined
  >({
    path: () => "merchants-svc/categories/merchants/recommend",
    queryParams: options.queryParams,
    lazy: options.lazy,
  });

const useGetMerchantSearch = (
  options: {
    queryParams?: MerchantSearchQueryParams;
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    MerchantSearchResponseData["search_results"],
    ErrorResponseData,
    MerchantSearchQueryParams,
    undefined
  >({
    path: () =>
      `merchants-svc/merchants/search${options.queryParams ? "" : "/default"}`,
    queryParams: options.queryParams,
    lazy: options.lazy,
    debounce: 200,
    resolve: (data: MerchantSearchResponseData) => data.search_results || [],
  });

export {
  useGetExplorePublicMerchantCategories,
  useGetMerchantSearch,
  useGetPublicMerchantByPublicKey,
  useGetPublicMerchantList,
  useGetRecommendedMerchants,
};
